/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { usePDF } from '@react-pdf/renderer';
import * as React from 'react';

const useStyles = createUseStyles({
  downloadAnchor: {
    display: 'none'
  }
});

interface PDFDownloadProps {
  fileName: string;
  document: React.ReactElement;
  downloadInitiated: () => void;
}

const PDFDownload: React.FC<PDFDownloadProps> = ({
  document,
  fileName,
  downloadInitiated
}) => {
  const styles = useStyles();
  const [instance, update] = usePDF({ document });
  const anchorNodeRef = useRef(null);

  useEffect(() => {
    if (instance.url) {
      anchorNodeRef.current.click();
      downloadInitiated();
    }
  }, [instance]);

  return (
    <a
      href={instance.url}
      ref={anchorNodeRef}
      className={styles.downloadAnchor}
      download={fileName}
    ></a>
  );
};

export default PDFDownload;
