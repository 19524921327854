import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { ToastContext } from '..';
import { getUserScans, getUsers, getUser } from '../api';
import Loading from '../components/Loading';
import { ScansTable } from './ScansTable';
import { SearchBar } from './SearchBar';
import { generateDataForTable } from './utils';

interface TableModel {
  userName: string;
  date: string;
  contact: string;
  scanId: string;
  userId: string;
}

export const ScanTablePage: React.FC = () => {
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<Array<TableModel>>([]);
  const [tableDataForSpecificUser, setTableDataForSpecificUser] = useState<
    Array<TableModel>
  >([]);
  const [currentUserId, setCurrentUserId] = useState('');
  const { setToast } = useContext(ToastContext);

  const clearSearchFilter = () => {
    setTableDataForSpecificUser([]);
    setCurrentUserId('');
  };

  const getScansForSpecificUser = async (userId) => {
    setIsLoading(true);
    setTableDataForSpecificUser([]);
    setCurrentUserId(userId);
    const customerData = await getUser(userId);
    const scans = await getUserScans(userId);
    const filterScans = scans.filter((scan) => scan.status === 'complete');

    const tableData = generateDataForTable(filterScans, customerData);
    if (tableData.length < 1) {
      setToast({
        text: 'The user was found but has no available scans',
      });
    }
    setTableDataForSpecificUser(tableData);
    setIsLoading(false);
  };

  const generateUserArray = async () => {
    setIsLoading(true);
    setTableData([]);
    setIsFullyLoaded(true);
    let continueFrom = true;
    const users = [];

    while (continueFrom) {
      const usersResponse = await getUsers(true, continueFrom);

      await generateScanArray(usersResponse.users);

      users.push(...usersResponse.users);

      if (usersResponse.continueFrom) {
        continueFrom = usersResponse.continueFrom;
      } else {
        continueFrom = false;
      }
    }
    setIsFullyLoaded(false);
    setIsLoading(false);
  };

  const generateScanArray = async (users) => {
    for (let i = 0; i < users.length; i++) {
      const currentUser = users[i];
      const scans = await getUserScans(currentUser.id);
      const filterScans = scans.filter((scan) => scan.status === 'complete');

      if (filterScans.length > 0) {
        setIsLoading(false);
        setTableData((prev) => [
          ...prev,
          ...generateDataForTable(filterScans, currentUser)
        ]);
      }
    }
  };

  const refreshHandler = () => {
    if (isFullyLoaded) return;

    if (currentUserId) {
      getScansForSpecificUser(currentUserId);
    } else {
      generateUserArray();
    }
  };

  const currentTableData = currentUserId ? tableDataForSpecificUser : tableData;

  useEffect(() => {
    generateUserArray();
  }, []);

  return (
    <>
      <div
        style={{ marginBottom: '40px' }}
        className="d-flex justify-content-center mt-3"
      >
        <img
          alt="Amplify logo"
          src="img/logo.png"
          style={{ width: '100%', maxWidth: '350px', objectFit: 'contain' }}
        />
      </div>
      <SearchBar
        clearSearchFilterHandler={clearSearchFilter}
        searchHandler={getScansForSpecificUser}
        showClearButton={!!currentUserId}
        isFullyLoaded={isFullyLoaded}
        refreshHandler={refreshHandler}
      />
      {isLoading ? (
        <Loading />
      ) : currentTableData.length > 0 ? (
        <>
          <ScansTable tableData={currentTableData} />
        </>
      ) : (
        <h3>Scan list is empty</h3>
      )}
    </>
  );
};
