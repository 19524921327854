import * as React from 'react';
import { useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { SearchModal, SearchType } from './SearchModal';

export interface SearchBarProps {
  searchHandler: (userId: string) => void;
}

const useStyles = createUseStyles({
  clearFilterButton: {
    border: 0,
    background: '#fff',
    outline: 'none',
    fontSize: '22px',
    textDecoration: 'underline',
    '&:hover': {
      opacity: 0.5
    }
  }
});

export const SearchBar: React.FC<
  SearchBarProps & {
    clearSearchFilterHandler: any;
    showClearButton: boolean;
    isFullyLoaded: boolean;
    refreshHandler: any;
  }
> = ({
  clearSearchFilterHandler,
  showClearButton,
  refreshHandler,
  isFullyLoaded,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchType, setSearchType] = useState<SearchType>('qrcode');
  const styles = useStyles();

  const clickHandler = (searchBy: SearchType) => {
    setIsModalOpen(true);
    setSearchType(searchBy);
  };

  const searchHandler = (userId) => {
    props.searchHandler(userId);
    setIsModalOpen(false);
  };

  return (
    <>
      <ButtonGroup style={{ marginBottom: 30 }} aria-label="Basic example">
        <Button onClick={() => clickHandler('qrcode')} variant="secondary">
          Find by QRcode
        </Button>
        <Button onClick={() => clickHandler('email')} variant="secondary">
          Find by Email
        </Button>
        <Button onClick={() => clickHandler('phone')} variant="secondary">
          Find by phone
        </Button>
      </ButtonGroup>
      <div className="d-flex justify-content-between align-items-center mb-3">
        {showClearButton && (
          <button
            onClick={clearSearchFilterHandler}
            className={styles.clearFilterButton}
          >
            Clear Search Filter
          </button>
        )}
        <Button disabled={isFullyLoaded} onClick={refreshHandler}>
          {isFullyLoaded ? 'Loading…' : 'Refresh Data'}
        </Button>
      </div>
      <SearchModal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        searchType={searchType}
        searchHandler={searchHandler}
      />
    </>
  );
};
