import API from '@aws-amplify/api';

const THREE_D_ID_API = {
  BASE_URI: 'three-d-id-api'
};

export const getApps = async () => {
  try {
    const path = '/apps';
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.error(e);
  }
};

export const getUsers = async (
  hasPagination?: boolean,
  startAfter?: string | boolean
) => {
  try {
    const path = startAfter ? `/users?startAfter=${startAfter}` : '/users';
    return await API.get(
      THREE_D_ID_API.BASE_URI,
      path,
      hasPagination
        ? {
            headers: {
              paginate: 'true'
            }
          }
        : {}
    );
  } catch (e) {
    console.error(e);
  }
};

export const getUserScans = async (userId) => {
  try {
    const path = `/users/${userId}/scans`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return -1;
  }
};

export const getScanImage = async (
  scanId,
  query = 'body-mesh?format=image/png',
  prefix = ''
) => {
  try {
    const path = `/scans/${scanId}/data/${query}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, {
      headers: {
        'file-prefix': prefix
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const getAppQuery = async (appId) => {
  try {
    const path = `/apps/${appId}/dataQueries`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getScan = async (scanId) => {
  try {
    const path = `/scans/${scanId}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getUser = async (userId) => {
  try {
    const path = `/users/${userId}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return ex.response;
  }
};

export const getCoreMeasurements = async (scanId) => {
  try {
    const path = `/scans/${scanId}/data/core-measures`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getAppMeasurements = async (scanId) => {
  try {
    const path = `/scans/${scanId}/data/app-measures`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getAppDateQueries = async (appId: string) => {
  try {
    const path = `/apps/${appId}/dataQueries`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.error(e);
  }
};

export const getDerivedData = async (scanId: string, query: string) => {
  try {
    const path = `/scans/${scanId}/data/${query}`;
    return await API.get(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.error(e);
  }
};

export const deleteScan = async (scanId) => {
  try {
    const path = `/scans/${scanId}`;
    return await API.del(THREE_D_ID_API.BASE_URI, path, null);
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return -1;
  }
};

export const getUserCountryCode = async () => {
  const res = await fetch(process.env.REACT_APP_IPAPI_URL);
  const countryCode = await res.text();

  return countryCode.toLowerCase();
};
