import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import {
  DropdownButton,
  Dropdown,
  Modal,
  ModalProps,
  Button
} from 'react-bootstrap';
import { ToastContext } from '..';
import { getScanImage } from '../api';
import Loading from '../components/Loading';
import { Scan } from './Scan';

const ScanImage: React.FC<{ scanUrl: string }> = ({ scanUrl }) => (
  <div className="d-flex justify-content-center">
    <img style={{ width: '100%', maxWidth: 600 }} src={scanUrl} />
  </div>
);

const customModalTitle = {
  tailor: 'Tailor'
};

type PreviewModalState = 'png' | 'tailor';

interface PreviewModalProps extends ModalProps {
  previewModalState: PreviewModalState;
  scanId: string;
  userId: string;
}

const PreviewModal: React.FC<PreviewModalProps> = ({
  previewModalState = 'png',
  scanId,
  userId,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [scanUrl, setScanUrl] = useState(null);
  const { setToast } = useContext(ToastContext);

  const renderSearchContent = () => {
    switch (previewModalState) {
      case 'png':
        return <ScanImage scanUrl={scanUrl} />;
      case 'tailor':
        return <Scan scanId={scanId} userId={userId} scanUrl={scanUrl} />;
      default:
        return null;
    }
  };

  const getScanData = async (scanId) => {
    setLoading(true);
    const response = await getScanImage(scanId);
    if (response.output.url) {
      setScanUrl(response.output.url);
    } else {
      setToast('PNG was not found for this scan');
    }
    setLoading(false);
  };

  useEffect(() => {
    getScanData(scanId);
  }, [scanId]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {customModalTitle.hasOwnProperty(previewModalState)
            ? customModalTitle[previewModalState]
            : previewModalState.toUpperCase()}{' '}
          preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{loading ? <Loading /> : renderSearchContent()}</Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

interface PreviewButtonProps {
  scanId: string;
  userId: string;
}

export const PreviewButton: React.FC<PreviewButtonProps> = ({
  scanId,
  userId
}) => {
  const [previewModalState, setPreviewModalState] =
    useState<PreviewModalState | null>();

  return (
    <>
      <DropdownButton title="Preview" className="me-2">
        <Dropdown.Item onClick={() => setPreviewModalState('png')}>
          Body Mesh (PNG)
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setPreviewModalState('tailor')}>
          Tailor Review
        </Dropdown.Item>
      </DropdownButton>
      {previewModalState && (
        <PreviewModal
          previewModalState={previewModalState}
          show={!!previewModalState}
          onHide={() => setPreviewModalState(null)}
          scanId={scanId}
          userId={userId}
        />
      )}
    </>
  );
};
