import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Form, InputGroup, FormControl, Button } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { ToastContext } from '..';
import { getUsers, getUserCountryCode } from '../api';
import Loading from '../components/Loading';
import { PhoneInputField } from '../components/PhoneInputField';
import { SearchBarProps } from './SearchBar';
import { validatePhoneNumber } from './validations';

const useStyles = createUseStyles({
  form: {
    padding: '50px 10px',
    maxWidth: '450px',
    margin: '0 auto'
  }
});

interface SearchByContactDataProps extends SearchBarProps {
  searchBy: 'email' | 'phone';
}

export const SearchByContactData: React.FC<SearchByContactDataProps> = ({
  searchBy,
  ...props
}) => {
  const [users, setUsers] = useState([]);
  const [userCountry, setUserCountry] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [inputState, setInputState] = useState('');
  const [countryData, setCountryData] = useState<{
    countryCode: string;
    dialCode: string;
  }>({ countryCode: '', dialCode: '' });
  const styles = useStyles();
  const { setToast } = useContext(ToastContext);

  const getUsersData = async () => {
    setLoading(true);
    let continueFrom = true;
    const users = [];

    while (continueFrom) {
      const usersResponse = await getUsers(true, continueFrom);

      users.push(...usersResponse.users);

      if (usersResponse.continueFrom) {
        continueFrom = usersResponse.continueFrom;
      } else {
        continueFrom = false;
      }
    }

    const country = await getUserCountryCode();
    setUserCountry(country);

    setUsers(users);
    setLoading(false);
  };

  const searchHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const trimmedInputState = inputState.trim();
    if (!trimmedInputState) {
      return setToast({
        text: "This field can't be empty!"
      });
    }

    let user;
    if (searchBy === 'email') {
      const lowerCasedInputState = trimmedInputState.toLowerCase();
      user = users.find(
        (user) => user.email?.toLowerCase() === lowerCasedInputState
      );
    } else if (searchBy === 'phone') {
      if (!validatePhoneNumber(inputState, countryData.countryCode)) {
        setToast({
          text: `Phone number isn't valid`
        });
        return;
      }
      user = users.find((user) => user.phone === trimmedInputState);
    }

    if (user) {
      props.searchHandler(user.id);
    } else {
      setToast({
        text: `No user found for ${trimmedInputState}`
      });
    }
  };

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState(e.target.value);
  };

  const phoneChangeHandler = (phoneNumber: string) => {
    setInputState(phoneNumber);
  };

  useEffect(() => {
    getUsersData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Form className={styles.form} onSubmit={searchHandler}>
      <h4>Enter user {searchBy}</h4>
      <InputGroup className="mb-3">
        {searchBy === 'phone' ? (
          <PhoneInputField
            value={inputState}
            handleChange={phoneChangeHandler}
            userCountry={userCountry}
            setCountryData={setCountryData}
            ariaLabel="Recipient's phone number"
            ariaDescribedby="basic-addon2"
          />
        ) : (
          <FormControl
            onChange={emailChangeHandler}
            value={inputState}
            name={searchBy}
            placeholder={searchBy}
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
        )}
        <Button type="submit">Search</Button>
      </InputGroup>
    </Form>
  );
};
