import * as React from 'react';
import { useMemo } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image
} from '@react-pdf/renderer';
import OpenSansRegular from '../assets/fonts/OpenSans-Regular.ttf';
import OpenSansBold from '../assets/fonts/OpenSans-Bold.ttf';

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: OpenSansRegular,
      fontWeight: 400
    },
    {
      src: OpenSansBold,
      fontWeight: 700
    }
  ]
});

const KG_INDEX = 0.453592;
const FT2_INDEX = 144;
const M2_INDEX = 0.092903;

const convertLbsToKg = (value) => {
  return value * KG_INDEX;
};

const getFt2 = (value) => {
  return value / FT2_INDEX;
};

const getM2 = (value) => {
  return (value / 144) * 0.092903;
};

const styles = StyleSheet.create({
  wrapper: {
    padding: '40px'
  },
  topSection: {
    marginBottom: 20,
  },
  personalInformationItem: {
    marginTop: 5,
    fontFamily: 'OpenSans',
    fontWeight: 700,
    fontSize: '14px'
  },
  scan: {
    width: 200,
    height: 200
  },
  scanImage: {
    marginRight: 20
  },
  measurementsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  square: {
    fontSize: '14px',
    width: 160,
    borderWidth: 1,
    borderColor: '#000',
    marginBottom: 20,
    padding: 14,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  squareLabel: {
    fontSize: '14px',
    paddingBottom: 10
  },
  measurements: {
    borderWidth: 1,
    borderColor: '#000'
  },
  measurementsTitleContainer: {
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  measurementsTitle: {
    fontSize: '14px',
  },
  measurementsBlock: {
    display: 'flex',
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: '#000'
  },
  measurementsCell: {
    width: 150,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  measurementsCellLeft: {
    borderRightWidth: 1,
    borderRightColor: '#000'
  },
  measurementsContent: {
    fontSize: '14px',
  },
  measurementsMainData: {
    marginRight: 50,
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  personalInformationBlock: {
    marginBottom: 20
  }
});

interface ScanPDFProp {
  measurements: Record<string, number>;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  scanUrl: string;
}

const ScanPDF: React.FC<ScanPDFProp> = ({
  measurements,
  firstName,
  lastName,
  email,
  phone,
  scanUrl
}) => {
  const measurementsData = useMemo(
    () => [
      { key: 'Left Bicep', value: measurements.BicepLeft },
      { key: 'Right Bicep', value: measurements.BicepRight },
      { key: 'Left Forearm', value: measurements.ForearmLeft },
      { key: 'Right Forearm', value: measurements.ForearmRight },
      { key: 'Waist', value: measurements.Waist },
      { key: 'Hip', value: measurements.Seat },
      { key: 'Left Thigh', value: measurements.ThighLeft },
      { key: 'Right Thigh', value: measurements.ThighRight },
      { key: 'Left Calf', value: measurements.CalfLeft },
      { key: 'Right Calf', value: measurements.CalfRight }
    ],
    []
  );

  return (
    <Document>
      <Page size="A4" style={styles.wrapper}>
        <View style={styles.measurementsContainer}>
            <View style={styles.measurementsMainData}>
              <View style={styles.square}>
                <Text style={styles.squareLabel}>Body Fat</Text>
                <Text>{measurements.BodyFat.toFixed(1)}%</Text>
              </View>
              <View style={styles.square}>
                <Text style={styles.squareLabel}>Lean Body Mass</Text>
                <Text>{measurements.LeanBodyMass.toFixed(1)} LBS</Text>
                <Text>
                  {convertLbsToKg(measurements.LeanBodyMass).toFixed(1)} KG
                </Text>
              </View>
              <View style={styles.square}>
                <Text style={styles.squareLabel}>Body Mineral Content</Text>
                <Text>{measurements.BmcTotal.toFixed(1)} g</Text>
              </View>
              <View style={styles.square}>
                <Text style={styles.squareLabel}>Lean Body Mass Arms</Text>
                <Text>{measurements.LeanMassArms.toFixed(1)} LBS</Text>
                <Text>
                  {convertLbsToKg(measurements.LeanMassArms).toFixed(1)} KG
                </Text>
              </View>
              <View style={styles.square}>
                <Text style={styles.squareLabel}>Lean Body Mass Legs</Text>
                <Text>{measurements.LeanMassLegs.toFixed(1)} LBS</Text>
                <Text>
                  {convertLbsToKg(measurements.LeanMassLegs).toFixed(1)} KG
                </Text>
              </View>
              <View style={styles.square}>
                <Text style={styles.squareLabel}>Visceral Adipose Tissue</Text>
                <Text>{measurements.VisceralAdiposeTissue.toFixed(1)} KG</Text>
              </View>
              <View style={styles.square}>
                <Text style={styles.squareLabel}>Body Surface Area %</Text>
                <Text>
                  {getFt2(measurements.BodySurfaceArea).toFixed(1)} FT&#178;
                </Text>
                <Text>
                  {getM2(measurements.BodySurfaceArea).toFixed(1)} M&#178;
                </Text>
              </View>
            </View>
            <View>
            <View style={styles.topSection}>
              <View style={styles.personalInformationBlock}>
                <Text style={styles.personalInformationItem}>
                  Customer: {firstName} {lastName}
                </Text>
                {!!email && (
                  <Text style={styles.personalInformationItem}>
                    Email: {email}
                  </Text>
                )}
                {!!phone && (
                  <Text style={styles.personalInformationItem}>
                    Phone: {phone}
                  </Text>
                )}
              </View>
              <View style={styles.scanImage}>
                <Image style={styles.scan} src={scanUrl} />
              </View>
            </View>
              <View style={styles.measurements}>
                <View style={styles.measurementsTitleContainer}>
                  <Text style={styles.measurementsTitle}>Measurements</Text>
                </View>

                {measurementsData.map(({ key, value }) => (
                  <View key={key} style={styles.measurementsBlock}>
                    <View
                      style={[
                        styles.measurementsCell,
                        styles.measurementsCellLeft
                      ]}
                    >
                      <Text style={styles.measurementsContent}>{key}</Text>
                    </View>
                    <View style={styles.measurementsCell}>
                      <Text style={styles.measurementsContent}>
                        {value.toFixed(1)} IN
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
      </Page>
    </Document>
  );
};

export default ScanPDF;
