import * as React from 'react';
import { useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Navbar, Container, Button } from 'react-bootstrap';
import { AppContext } from '..';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

const useStyles = createUseStyles({
  logo: {
    '@media screen and (max-width: 576px)': {
      fontSize: '20px'
    }
  }
});

const Header = () => {
  const appData = useContext(AppContext);
  const styles = useStyles();

  return (
    <Navbar expand="lg" bg="light" variant="dark" className="mb-4">
      <Container>
        <div
          className="d-flex justify-content-between w-100 align-items-center"
          style={{ maxWidth: '950px', margin: '0 auto', position: 'relative' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to="/" style={{ color: '#000', textDecoration: 'none' }}>
              <h3 style={{ marginBottom: 0 }} className={styles.logo}>
                {appData.name}
              </h3>
            </Link>
          </div>
          <div
            className="d-inline-flex"
            style={{
              position: 'absolute',
              right: 0
            }}
          >
            <Button variant="link" className="d-none d-md-inline-block">
              <a
                target="_blank"
                href="https://www.sizestream.com/contact"
                rel="noreferrer"
              >
                Contact Support
              </a>
            </Button>
            <Button variant="secondary" onClick={signOut}>
              Log Out
            </Button>
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
