export const columns = [
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'User Name',
    accessor: 'userName'
  },
  {
    Header: 'Contact',
    accessor: 'contact'
  },
  {
    accessor: 'scanId'
  }
];

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
};

export const getDateWithCorrectFormat = (date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate())
    ].join('-') +
    ' ' +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(':')
  );
};

export const generateDataForTable = (scans: Array<any>, user: any) => {
  return scans.map((scan) => ({
    date: getDateWithCorrectFormat(new Date(scan.date)),
    userName: `${user.firstName} ${user.lastName}`,
    contact: user.email || user.phone,
    scanId: scan.id,
    userId: user.id
  }));
};
