import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { getScanImage, getAppMeasurements, getUser } from '../api';
import { AppContext } from '..';
import ScanPDF from './ScanPDF';
import PDFDownload from './PDFDownload';
import { useGenerateDataURL } from '../hooks/common';

const FITNESS = 'fitness';
const fitnessConfig = {
  displayName: 'Fitness (PDF)',
  isCustomHandled: true,
  customHandlingData: {
    type: FITNESS
  }
};

const formatDateForFile = (dateString: string): string => {
  const date = new Date(dateString.replace(/ /g, 'T'));

  return [
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getDate().toString().padStart(2, '0'),
    (date.getHours() % 12 ? date.getHours() % 12 : 12)
      .toString()
      .padStart(2, '0'),
    date.getMinutes().toString().padStart(2, '0'),
    date.getSeconds().toString().padStart(2, '0'),
    date.getHours() < 12 ? 'AM' : 'PM'
  ].join('_');
};

interface DownloadButtonProps {
  scanId: string;
  userId: string;
  date: string;
  contact: string;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  scanId,
  userId,
  date,
  contact
}) => {
  const { dataQueries } = useContext(AppContext);
  const [combinedDataQueries, setCombinedDataQueries] = useState<Array<any>>([
    ...dataQueries,
    fitnessConfig
  ]);
  const [triggerFitnessPDFDownload, setTriggerFitnessPDFDownload] =
    useState(false);
  const [appMeasurements, setAppMeasurements] =
    useState<Record<string, number>>(null);
  const [scanImage, setScanImage] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const scanUrl = useGenerateDataURL(scanImage, triggerFitnessPDFDownload);

  useEffect(() => {
    if (dataQueries) {
      setCombinedDataQueries([...dataQueries, fitnessConfig]);
    }
  }, [dataQueries]);

  const getScanData = async ({ scanId, query }) => {
    const prefix = `${contact}-${formatDateForFile(date)}-`;
    const response = await getScanImage(scanId, query, prefix);
    if ('output' in response) {
      if ('url' in response.output) {
        window.location.assign(response.output.url);
      }
    }
  };

  const handleOnClickItem = (dataQuery) => {
    if (dataQuery.customHandlingData.type === FITNESS) {
      setLoading(true);
      setTriggerFitnessPDFDownload(true);
    }
  };

  const onFitnessPDFDownloadInitiated = () => {
    setTriggerFitnessPDFDownload(false);
  };

  useEffect(() => {
    if (!triggerFitnessPDFDownload) return;

    (async () => {
      const _scanImage = await getScanImage(scanId);
      setScanImage(_scanImage.output.url);

      const _appMeasurements = await getAppMeasurements(scanId);
      setAppMeasurements(_appMeasurements.output);

      const _customer = await getUser(userId);
      setCurrentCustomer(_customer);

      setLoading(false);
    })();
  }, [triggerFitnessPDFDownload]);

  if (!dataQueries) return null;

  return (
    <>
      <DropdownButton variant="secondary" title="Download">
        {combinedDataQueries.map((dataQuery) => {
          if (dataQuery.isCustomHandled) {
            return (
              <Dropdown.Item
                key={dataQuery.customHandlingData.type}
                disabled={triggerFitnessPDFDownload}
                onClick={() => handleOnClickItem(dataQuery)}
              >
                {dataQuery.displayName}
              </Dropdown.Item>
            );
          } else if (dataQuery.query && dataQuery.query !== 'app-measures') {
            return (
              <Dropdown.Item
                key={dataQuery.query}
                onClick={() =>
                  getScanData({
                    scanId,
                    query: dataQuery.query
                  })
                }
              >
                {dataQuery.displayName}
              </Dropdown.Item>
            );
          }
        })}
      </DropdownButton>
      {triggerFitnessPDFDownload && !loading && !!scanUrl && (
        <PDFDownload
          document={
            <ScanPDF
              measurements={appMeasurements}
              firstName={currentCustomer.firstName}
              lastName={currentCustomer.lastName}
              email={currentCustomer.email}
              phone={currentCustomer.phone}
              scanUrl={scanUrl}
            />
          }
          fileName="scan.pdf"
          downloadInitiated={onFitnessPDFDownloadInitiated}
        />
      )}
    </>
  );
};
